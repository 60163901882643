import { createApp } from 'vue'
import App from './App.vue'
import mitt from 'mitt'
import './assets/styleDark.css'
import VueToast from "vue-toast-notification";
import 'vue-toast-notification/dist/theme-sugar.css';

/* original vue 3 setup */
/* createApp(App).mount('#app') */

/* modified vue 3 setup */
const eventBus = mitt()
const app = createApp(App)

app.config.globalProperties.eventBus = eventBus

//https://github.com/ankurk91/vue-toast-notification

app.use(VueToast, {
    /* position: "bottom",
    duration: 5000,
    queue: true */
}).mount('#app')



/* app.mount('#app') */

<template>
    <div class="header-area">
        <div class="header-title"><h1>Agency User Lookup</h1></div>
        <ul class="header-nav">
            <!-- <li>Home</li> -->
            <li><i class="fa fa-home header-icon-color" title="Home"></i></li>
            <!-- <li @click="showAPIModal">API Settings</li> -->
            <li @click="showAPIModal">
                <i class="fa fa-cog header-icon-color" aria-hidden="true" title="API Settings"></i>
            </li>
            <!-- <li>Logout</li> -->
            <li><i class="fa fa-sign-out header-icon-color" title="Sign In"></i></li>
        </ul>
        <APIModalSection v-show="isModalVisible"
                @close="closeAPIModal"></APIModalSection>
    </div>
</template>

<script>
import APIModalSection from './APIModal.vue';

export default {
    name: 'HeaderSection',
    components: {
        APIModalSection,
    },
    data() {
        return {
            isModalVisible: false,
        };
    },
    methods: {
        showAPIModal() {
            this.isModalVisible = true;
        },
        closeAPIModal() {
            this.isModalVisible = false;
        },
    },
}
</script>

<template>
    <div class="side-area">
        <div class="search-type">
            <input type="radio" id="pers" value="pers" v-model="searchpicked" name="searchby">
            <label class="search-by-text">Person</label>
            <input type="radio" id="ext" value="ext" v-model="searchpicked" name="searchby">
            <label class="search-by-text">Ext.</label>

            <input type="radio" id="tmob" value="tmob" v-model="searchpicked" name="searchby">
            <label class="search-by-text">TMob</label>

            <input type="radio" id="dept" value="dept" v-model="searchpicked" name="searchby">                
            <label class="search-by-text">Dept.</label>
            <input type="radio" id="comp" value="comp" v-model="searchpicked" name="searchby">                
            <label class="search-by-text">Comp.</label>                
        </div>
        <div class="search-area">
            <div class="search-container">
                <i class="fa fa-search searchIcon" style="color:#82A8F2"></i>
                <input class="searchBox" type="search" name="search" placeholder="Search..." 
                    v-model="user"
                    v-on:keyup.enter="getSearchBy(searchpicked)">
                <input type="submit" value="Search" class="searchButton" @click="getSearchBy(searchpicked)">
            </div>
        </div>
        <div class="user-area">
            <div class="card-section-header"></div>
            <div class="card-section-title">
                <div>
                    <i class="fa fa-users" style="color:grey"></i>
                    Users
                </div>
                <div class="usercount" v-if="showcount === 1">
                    {{ usercount }}
                </div>
                <!-- <div v-if="newuser === 'new' || deluser === 'del'"> -->
                <div v-if="newuser === 'new'">
                    <span class="editicon" @click="newUser">
                        <i class="fa fa-regular fa-plus-circle edit-icon-color"></i>
                    </span>  
                </div>
                <div v-if="deluser === 'del'">
                    <span class="editicon" @click="delUser">
                        <i class="fa fa-regular fa-minus-circle edit-icon-color"></i>
                    </span>    
                </div>
                <div v-if="newuser === 'edit'">
                    <span class="editicon" @click="saveNewUser">
                        <i class="fa fa-solid fa-check save-icon-color"></i>
                    </span>
                    <span class="editicon" @click="exitNewUser">
                        <i class="fa fa-solid fa-times exit-icon-color"></i>
                    </span>
                </div>
            </div>
            <div class="table-area">
                <ul id="userList">
                    <li v-for="name in names" :key="name.id" @click="getUser(name)">{{ name.name }}</li>
                </ul>
            </div>
        </div>
        <ConfirmDeleteSection v-show="isDeleteModalVisible"
                @close="closeDeleteModal"></ConfirmDeleteSection>
    </div>
</template>


<script>
    import ConfirmDeleteSection from './ConfirmDelete.vue';
    import axios from 'axios';
    const GRAPHQL_API_URI = "https://nexidom.com:4001/graphql";
    import customMessage from '../constants/CustomMessage';

    export default {
        name: "SideSection",
        components: {
            ConfirmDeleteSection,
        },
        data() {
            return {
                user: '',
                names: [],
                searchpicked: '',
                GRAPHQL_API_KEY: '',
                GRAPHQL_BEARER_TOKEN: '',
                newuser: 'new',
                deluser: '',
                peopleCount: '',
                selectedUser: '',
                selectedContactID: '',
                selectedUserID: '',
                isDeleteModalVisible: false,
                usercount: 0,
                showcount: 0,
            };
        },
        methods: {
            getSearchBy(getby) {
                this.newuser = "new";
                this.deluser = "";
                if (getby === "pers") { this.getPerson(); }
                if (getby === "ext") { this.getPersonExtension(); }
                if (getby === "tmob") { this.getPersonTMobile(); }
                if (getby === "dept") { this.getDepartment(); }
                if (getby === "comp") { this.getCompany(); }
            },
            getPerson() {
                const fetchPerson = async () => {
                const queryResult = await axios.post (
                  GRAPHQL_API_URI, {
                    query: `
                        query {
                            getPerson(name:"${this.user}") {
                                id
                                idx
                                name
                                samAccountName
                                employeeNumber
                                company
                                office
                                mail
                                title
                                department
                                manager
                                homeDrive
                                homeDirectory
                                assetsID
                                asmEmail
                                contacts{
                                    id
                                    idx
                                    name
                                    samAccountName
                                    extension
                                    telephoneNumber
                                    maxUC
                                    tMobile
                                }
                            }
                        }
                    `,
                  }, {
                    headers: {
                      'x-api-key': this.GRAPHQL_API_KEY,
                      Authorization: 'Bearer ' + this.GRAPHQL_BEARER_TOKEN,
                    }
                  }
                );
                const result = queryResult.data.data;
                //console.log(result.getPerson);
                //this.userName = result.getPerson[0].name;
                //this.mail = result.getPerson[0].mail;
                //this.ext = result.getPerson[0].contacts[0].extension;
                this.names = result.getPerson;
                if (this.names === null) {
                        this.usercount = 0;
                        this.showcount = 0;
                        this.clearUser();
                }
                try {
                    if (result.getPerson === null) {
                        this.clearUser();
                    } else {
                        this.usercount = this.names.length;
                        this.showcount = 1;
                    }
                } catch (err) {
                    //throw new Error;
                }
            };
            fetchPerson();
            },
            getPersonExtension() {
                const fetchPersonExtension = async () => {
                if (isNaN(this.user) || this.user.toString().trim() === '') {
                    this.$toast.open(customMessage.infoMsg('The extension lookup has to be a number'), {});
                    this.names = '';
                    this.usercount = 0;
                    this.showcount = 0;
                    this.clearUser();
                }
                else { 
                    const queryResult = await axios.post (
                    GRAPHQL_API_URI, {
                        query: `
                            query {
                                getPersonExtension(extension:${this.user}) {
                                    id
                                    idx
                                    name
                                    samAccountName
                                    employeeNumber
                                    company
                                    office
                                    mail
                                    title
                                    department
                                    manager
                                    homeDrive
                                    homeDirectory
                                    assetsID
                                    asmEmail
                                    extension
                                    telephoneNumber
                                    maxUC
                                    tMobile
                                    contacts{
                                        id
                                        idx
                                        name
                                        samAccountName
                                        extension
                                        telephoneNumber
                                        maxUC
                                        tMobile
                                    }
                                }
                            }
                        `,
                    }, {
                        headers: {
                        'x-api-key': this.GRAPHQL_API_KEY,
                        Authorization: 'Bearer ' + this.GRAPHQL_BEARER_TOKEN,
                        }
                    }
                    );
                    const result = queryResult.data.data;
                    this.names = result.getPersonExtension;
                    if (this.names === null) {
                        this.usercount = 0;
                        this.showcount = 0;
                        this.clearUser();
                    }
                    try {
                        if (result.getPersonExtension === null) {
                            this.clearUser();
                        } else {
                            this.usercount = this.names.length;
                            this.showcount = 1;
                        }
                    } catch (err) {
                        //throw new Error;
                    }
                }
            };
            fetchPersonExtension();
            },
            getPersonTMobile() {
                const fetchPersonTMobile = async () => {
                /* if (isNaN(this.user) || this.user.toString().trim() === '') {
                    this.$toast.open(customMessage.infoMsg('The extension lookup has to be a number'), {});
                    this.names = '';
                    this.usercount = 0;
                    this.showcount = 0;
                    this.clearUser();
                }
                else { */ 
                    const queryResult = await axios.post (
                    GRAPHQL_API_URI, {
                        query: `
                            query {
                                getPersonTMobile(tMobile:"${this.user}") {
                                    id
                                    idx
                                    name
                                    samAccountName
                                    employeeNumber
                                    company
                                    office
                                    mail
                                    title
                                    department
                                    manager
                                    homeDrive
                                    homeDirectory
                                    assetsID
                                    asmEmail
                                    extension
                                    telephoneNumber
                                    maxUC
                                    tMobile
                                    contacts{
                                        id
                                        idx
                                        name
                                        samAccountName
                                        extension
                                        telephoneNumber
                                        maxUC
                                        tMobile
                                    }
                                }
                            }
                        `,
                    }, {
                        headers: {
                        'x-api-key': this.GRAPHQL_API_KEY,
                        Authorization: 'Bearer ' + this.GRAPHQL_BEARER_TOKEN,
                        }
                    }
                    );
                    const result = queryResult.data.data;
                    this.names = result.getPersonTMobile;
                    if (this.names === null) {
                        this.usercount = 0;
                        this.showcount = 0;
                        this.clearUser();
                    }
                    try {
                        if (result.getPersonTMobile === null) {
                            this.clearUser();
                        } else {
                            this.usercount = this.names.length;
                            this.showcount = 1;
                        }
                    } catch (err) {
                        //throw new Error;
                    }
                //}
            };
            fetchPersonTMobile();
            },
            getDepartment() {
                const fetchDepartment = async () => {
                if (this.user.toString().trim() === '') {
                    this.names = '';
                    this.usercount = 0;
                    this.showcount = 0;
                    this.clearUser();
                }
                else {
                    const queryResult = await axios.post (
                    GRAPHQL_API_URI, {
                        query: `
                            query {
                                getDepartment(department:"${this.user}") {
                                    id
                                    idx
                                    name
                                    samAccountName
                                    employeeNumber
                                    company
                                    office
                                    mail
                                    title
                                    department
                                    manager
                                    homeDrive
                                    homeDirectory
                                    assetsID
                                    asmEmail
                                    contacts{
                                        id
                                        idx
                                        name
                                        samAccountName
                                        extension
                                        telephoneNumber
                                        maxUC
                                        tMobile
                                    }
                                }
                            }
                        `,
                    }, {
                        headers: {
                        'x-api-key': this.GRAPHQL_API_KEY,
                        Authorization: 'Bearer ' + this.GRAPHQL_BEARER_TOKEN,
                        }
                    }
                    );
                    const result = queryResult.data.data;
                    //console.log(result);
                    this.names = result.getDepartment;
                    if (this.names === null) {
                        this.usercount = 0;
                        this.showcount = 0;
                        this.clearUser();
                    }
                    try {
                        if (result.getDepartment === null) {
                            this.clearUser();
                        } else {
                            this.usercount = this.names.length;
                            this.showcount = 1;
                        }
                    } catch (err) {
                        //throw new Error;
                    }
                }
            };
            fetchDepartment();
            },
            getCompany() {
                const fetchCompany = async () => {
                if (this.user.toString().trim() === '') {
                    this.names = '';
                    this.usercount = 0;
                    this.showcount = 0;
                    this.clearUser();
                }
                else {
                    const queryResult = await axios.post (
                    GRAPHQL_API_URI, {
                        query: `
                            query {
                                getCompany(company:"${this.user}") {
                                    id
                                    idx
                                    name
                                    samAccountName
                                    employeeNumber
                                    company
                                    office
                                    mail
                                    title
                                    department
                                    manager
                                    homeDrive
                                    homeDirectory
                                    assetsID
                                    asmEmail
                                    contacts{
                                        id
                                        idx
                                        name
                                        samAccountName
                                        extension
                                        telephoneNumber
                                        maxUC
                                        tMobile
                                    }
                                }
                            }
                        `,
                    }, {
                        headers: {
                        'x-api-key': this.GRAPHQL_API_KEY,
                        Authorization: 'Bearer ' + this.GRAPHQL_BEARER_TOKEN,
                        }
                    }
                    );
                    const result = queryResult.data.data;
                    this.names = result.getCompany;
                    if (this.names === null) {
                        this.usercount = 0;
                        this.showcount = 0;
                        this.clearUser();
                    }
                    try {
                        if (result.getCompany === null) {
                            this.clearUser();
                        } else {
                            this.usercount = this.names.length;
                            this.showcount = 1;
                        }
                    } catch (err) {
                        //throw new Error;
                    }
                }
            };
            fetchCompany();
            },
            getUser(person) {
                this.selectedUser = person.name;
                this.selectedUserID = person.id;
                this.selectedContactID = person.contacts[0].id;
                this.eventBus.emit("send-user", { 
                        user: person,
                        typeAD: "read",
                        typeProfile: "read",
                        typeContact: "read",
                        typeMisc: "read",
                });
                this.newuser = "new";
                this.deluser = "del";
            },
            newUser() {
                this.eventBus.emit("new-user", { 
                        typeNewUser: "new",
                });
                this.newuser = "edit";
                if (this.names !== null) {
                    this.names = "";
                }
                this.deluser = "";
                this.usercount = 0;
                this.showcount = 0;
            },
            exitNewUser() {
                this.eventBus.emit("exit-user", {
                        typeAD: "",
                        typeProfile: "",
                        typeContact: "",
                        typeMisc: "",
                });
                this.newuser = "new";
                if (this.names !== null) {
                    this.names = "";
                }
            },
            clearUser() {
                this.eventBus.emit("exit-user", {
                        typeAD: "",
                        typeProfile: "",
                        typeContact: "",
                        typeMisc: "",
                });
                this.newuser = "new";
            },
            saveNewUser() {
                const getPeopleCount = async () => {
                    const queryResult = await axios.post (
                        GRAPHQL_API_URI, {
                        query: `
                            query {
                                people {
                                    idx
                                }
                            }
                        `,
                        }, {
                        headers: {
                            'x-api-key': this.GRAPHQL_API_KEY,
                            Authorization: 'Bearer ' + this.GRAPHQL_BEARER_TOKEN,
                        }
                        }
                    );
                    const result = queryResult.data.data;
                    result.people.sort((a, b) => a.idx - b.idx);
                    let lastpeopleCount = result.people[result.people.length - 1];
                    let intpeopleCount = parseInt(lastpeopleCount.idx, 10);
                    intpeopleCount++;
                    this.eventBus.emit("save-user", {
                        personidx: intpeopleCount,
                    });
                };
                getPeopleCount();
            },
            delUser() {
                this.newuser = "new";
                this.showDeleteModal(this.selectedUser, this.selectedUserID, this.selectedContactID);
            },
            showDeleteModal(user, userid, contactid) {
                this.isDeleteModalVisible = true;
                this.eventBus.emit("confirm-del-user", {
                    delThisUser: user,
                    delThisUserID: userid,
                    delThisContactID: contactid,
                });
            },
            closeDeleteModal() {
                this.isDeleteModalVisible = false;
            },
        },
        computed: {
            
        },
        created() {
            this.eventBus.on("reset-newuser-button", ({newuser}) => {
                this.newuser = newuser;
            });
            this.eventBus.on("clear-delete-user", () => {
                this.names = "";
                this.deluser = "";
                this.usercount = 0;
                this.showcount = 0;
            });
        },
        mounted() {
            if (localStorage.apikey) {
                this.GRAPHQL_API_KEY = localStorage.apikey;
            }
            if (localStorage.apisecret) {
                this.GRAPHQL_BEARER_TOKEN = localStorage.apisecret;
            }
        }
    }
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');
</style>
<template>
    <div class="middle-area">
        <div class="card-area">
            <div class="card-section-header"></div>
            <div class="card-section-title">
                <div>
                    <i class="fa fa-address-book" style="color:grey" ></i>
                    AD Section
                </div>
                <div v-if="typeAD === 'read'">
                    <span class="editicon" @click="editAD">
                        <i class="fa fa-regular fa-pencil edit-icon-color"></i>
                    </span>    
                </div>
                <div v-if="typeAD === 'edit'">
                    <span class="editicon" @click="saveAD">
                        <i class="fa fa-solid fa-check save-icon-color"></i>
                    </span>
                    <span class="editicon" @click="exitAD">
                        <i class="fa fa-solid fa-times exit-icon-color"></i>
                    </span>
                </div>
            </div>
            <div v-if="typeAD === 'read'" class="ad-data">
                <section class=card-section-grid>
                    <div>Name:</div>
                    <div>{{ name }}</div>

                    <div>Login:</div>
                    <div>{{ samaccountname }}</div>

                    <div>Email:</div>
                    <div>{{ email }}</div>

                    <div>Title:</div>
                    <div>{{ title }}</div>
                </section>
            </div>
            <div v-if="typeAD === 'edit'">
                <section class=card-section-grid>
                    <div>Name:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="name"/>
                    </div>

                    <div>Login:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="samaccountname"/>
                    </div>

                    <div>Email:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="email"/>
                    </div>

                    <div>Title:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="title"/>
                    </div>
                </section>
            </div>
            <div v-if="typeNewUser === 'new'">
                <section class=card-section-grid>
                    <div>Name:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="name"/>
                    </div>

                    <div>Login:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="samaccountname"/>
                    </div>

                    <div>Email:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="email"/>
                    </div>

                    <div>Title:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="title"/>
                    </div>
                </section>
            </div>
        </div>
        <div class="card-area">
            <div class="card-section-header"></div>
            <div class="card-section-title">
                <div>
                    <i class="fa fa-address-card" style="color:grey"></i>
                    Profile Section
                </div>
                <div v-if="typeProfile === 'read'">
                    <span class="editicon" @click="editProfile">
                        <i class="fa fa-regular fa-pencil edit-icon-color"></i>
                    </span>
                </div>
                <div v-if="typeProfile === 'edit'">
                    <span class="editicon" @click="saveProfile">
                        <i class="fa fa-solid fa-check save-icon-color"></i>
                    </span>
                    <span class="editicon" @click="exitProfile">
                        <i class="fa fa-solid fa-times exit-icon-color"></i>
                    </span>
                </div>
            </div>
            <div v-if="typeProfile === 'read'" class="ad-data">
                <section class=card-section-grid>
                    <div>Manager:</div>
                    <div>{{ manager }}</div>

                    <div>Department:</div>
                    <div>{{ department }}</div>

                    <div>Office:</div>
                    <div>{{ office }}</div>

                    <div>Company:</div>
                    <div>{{ company }}</div>
                </section>
            </div>
            <div v-if="typeProfile === 'edit'">
                <section class=card-section-grid>
                    <div>Manager:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="manager"/>
                    </div>

                    <div>Department:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="department"/>
                    </div>

                    <div>Office:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="office"/>
                    </div>

                    <div>Company:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="company"/>
                    </div>
                </section>
            </div>
            <div v-if="typeNewUser === 'new'">
                <section class=card-section-grid>
                    <div>Manager:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="manager"/>
                    </div>

                    <div>Department:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="department"/>
                    </div>

                    <div>Office:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="office"/>
                    </div>

                    <div>Company:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="company"/>
                    </div>
                </section>
            </div>
        </div>
        <div class="card-area">
            <div class="card-section-header"></div>
            <div class="card-section-title">
                <div>
                    <i class="fa fa-phone-square" style="color:grey"></i>
                    Contact Section
                </div>
                <div v-if="typeContact === 'read'">
                    <span class="editicon" @click="editContact">
                        <i class="fa fa-regular fa-pencil edit-icon-color"></i>
                    </span>
                </div>
                <div v-if="typeContact === 'edit'">
                    <span class="editicon" @click="saveContact">
                        <i class="fa fa-solid fa-check save-icon-color"></i>
                    </span>
                    <span class="editicon" @click="exitContact">
                        <i class="fa fa-solid fa-times exit-icon-color"></i>
                    </span>
                </div>
            </div>
            <div v-if="typeContact === 'read'" class="ad-data">
                <section class=card-section-grid>
                    <div>Telephone:</div>
                    <div class="data-highlight">{{ telephone }}</div>

                    <div>Extension:</div>
                    <div class="data-highlight">{{ extension }}</div>

                    <div>MaxUC:</div>
                    <div class="data-highlight">{{ maxuc }}</div>

                    <div>T-Mobile:</div>
                    <div class="data-highlight">{{ tmobile }}</div>
                </section>
            </div>
            <div v-if="typeContact === 'edit'">
                <section class=card-section-grid>
                    <div>Telephone:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="telephone"/>
                    </div>

                    <div>Extension:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="extension"/>
                    </div>

                    <div>MaxUC:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="maxuc"/>
                    </div>

                    <div>T-Mobile:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="tmobile"/>
                    </div>
                </section>
            </div>
            <div v-if="typeNewUser === 'new'">
                <section class=card-section-grid>
                    <div>Telephone:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="telephone"/>
                    </div>

                    <div>Extension:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="extension"/>
                    </div>

                    <div>MaxUC:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="maxuc"/>
                    </div>

                    <div>T-Mobile:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="tmobile"/>
                    </div>
                </section>
            </div>
        </div>
        <div class="card-area">
            <div class="card-section-header"></div>
            <div class="card-section-title">
                <div>
                    <i class="fa fa-folder-open" style="color:grey"></i>
                    Misc Section
                </div>
                <div v-if="typeMisc === 'read'">
                    <span class="editicon" @click="editMisc">
                        <i class="fa fa-regular fa-pencil edit-icon-color"></i>
                    </span>
                </div>
                <div v-if="typeMisc === 'edit'">
                    <span class="editicon" @click="saveMisc">
                        <i class="fa fa-solid fa-check save-icon-color"></i>
                    </span>
                    <span class="editicon" @click="exitMisc">
                        <i class="fa fa-solid fa-times exit-icon-color"></i>
                    </span>
                </div>
            </div>
            <div v-if="typeMisc === 'read'" class="ad-data">
                <section class=card-section-grid>
                    <div>Employee No:</div>
                    <div>{{ employeenumber }}</div>

                    <div>Home Directory:</div>
                    <div>{{ homedirectory }}</div>

                    <div>Assets ID:</div>
                    <div class="data-highlight"><a :href="'https://assets.mcbdd.org/users/' + assetsid" target="_blank">Assets</a></div>

                    <div>Apple ID:</div>
                    <div>{{ asmemail }}</div>
                    
                </section>
            </div>
            <div v-if="typeMisc === 'edit'">
                <section class=card-section-grid>
                    <div>Employee No:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="employeenumber"/>
                    </div>

                    <div>Home Directory:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="homedirectory"/>
                    </div>

                    <div>Assets ID:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="assetsid"/>
                    </div>

                    <div>Apple ID:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="asmemail"/>
                    </div>
                </section>
            </div>
            <div v-if="typeNewUser === 'new'">
                <section class=card-section-grid>
                    <div>Employee No:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="employeenumber"/>
                    </div>

                    <div>Home Directory:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="homedirectory"/>
                    </div>

                    <div>Assets ID:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="assetsid"/>
                    </div>

                    <div>Apple ID:</div>
                    <div>
                        <input class="edit-fields" type="text" v-model="asmemail"/>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    const GRAPHQL_API_URI = 'https://nexidom.com:4001/graphql';
    import customMessage from '../constants/CustomMessage';

    export default {
        name: 'MiddleSection',
        data() {
            return {
                GRAPHQL_API_KEY: '',
                GRAPHQL_BEARER_TOKEN: '',
                idx: '',
                name: '',
                samaccountname: '',
                email: '',
                title: '',
                manager: '',
                department: '',
                office: '',
                employeenumber: '',
                company: '',
                contactid: '',
                telephone: '',
                extension: '',
                maxuc: '',
                tmobile: '',
                homedirectory: '',
                assetsid: '',
                asmemail: '',
                typeAD: '',
                typeProfile: '',
                typeContact: '',
                typeMisc: '',
                typeNewUser: '',
                personidx: '',
            };
        },
        created() {
            this.eventBus.on("send-user", ({user, typeAD, typeProfile, typeContact, typeMisc}) => {
                this.idx = user.idx;
                this.name = user.name;
                this.samaccountname = user.samAccountName;
                this.email = user.mail;
                this.title = user.title;
                this.manager = user.manager;
                this.department = user.department;
                this.office = user.office;
                this.employeenumber = user.employeeNumber;
                this.company = user.company;

                this.contactid = user.contacts[0].id;
                this.telephone = user.contacts[0].telephoneNumber;
                this.extension = user.contacts[0].extension;
                this.maxuc = user.contacts[0].maxUC;
                this.tmobile = user.contacts[0].tMobile;

                this.homedirectory = user.homeDirectory;
                this.assetsid = user.assetsID;
                this.asmemail = user.asmEmail;
                this.typeAD = typeAD;
                this.typeProfile = typeProfile;
                this.typeContact = typeContact;
                this.typeMisc = typeMisc;
                this.typeNewUser = "read";
            });
            this.eventBus.on("new-user", ({typeNewUser, personidx}) => {
                this.typeNewUser = typeNewUser;
                this.personidx = personidx;
                this.typeAD = "new";
                this.typeProfile = "new";
                this.typeContact = "new";
                this.typeMisc = "new";
                this.name = "";
                this.samaccountname = "";
                this.email = "";
                this.title = "";
                this.manager = "";
                this.department = "";
                this.office = "";
                this.employeenumber = "";
                this.company = "";
                this.telephone = "";
                this.extension = "";
                this.maxuc = "";
                this.tmobile = "";
                this.homedirectory = "";
                this.assetsid = "";
                this.asmemail = "";
            });
            this.eventBus.on("exit-user", ({typeAD, typeProfile, typeContact, typeMisc}) => {
                this.typeAD = typeAD;
                this.typeProfile = typeProfile;
                this.typeContact = typeContact;
                this.typeMisc = typeMisc;
                this.typeNewUser = "read";
            });
            this.eventBus.on("save-user", ({personidx}) => {
               this.personidx = personidx;

               if (this.name.trim() === '') {
                   this.$toast.open(customMessage.warningMsg('The name field cannot be empty'), {});
               }else if (isNaN(this.extension) || this.extension.toString().trim() === '') {
                   this.$toast.open(customMessage.warningMsg('The extension field has to be a number'), {});
               }else if (this.extension.length > 1 && this.extension.toString().charAt(0) === '0') {
                   this.$toast.open(customMessage.warningMsg('Enter 0 for no extension'), {});    
               } else {
                    const saveNewUserPerson = async () => {
                        const queryResult = await axios.post (
                        GRAPHQL_API_URI, {
                            query: `
                                mutation {
                                    addPerson(input: {
                                    idx: ${this.personidx},
                                    name: "${this.name}",
                                    samAccountName: "${this.samaccountname}",
                                    employeeNumber: "${this.employeenumber}",
                                    company: "${this.company}",
                                    office: "${this.office}",
                                    mail: "${this.email}",
                                    title: "${this.title}",
                                    department: "${this.department}",
                                    manager: "${this.manager}",
                                    homeDirectory: "${this.homedirectory}",
                                    assetsID: "${this.assetsid}",
                                    asmEmail: "${this.asmemail}",
                                    extension: ${this.extension},
                                    telephoneNumber: "${this.telephone}",
                                    maxUC: "${this.maxuc}",
                                    tMobile: "${this.tmobile}",
                                    }) {
                                        id,
                                        name,
                                        samAccountName,
                                    }
                                }
                            `,
                        }, {
                            headers: {
                            'x-api-key': this.GRAPHQL_API_KEY,
                            Authorization: 'Bearer ' + this.GRAPHQL_BEARER_TOKEN,
                            }
                        }
                        );
                        const result = queryResult.data.data;
                        this.names = result;
                    };
                    saveNewUserPerson();
                    const saveNewUserPhone = async () => {
                        const queryResult = await axios.post (
                        GRAPHQL_API_URI, {
                            query: `
                                mutation {
                                    addPhone(input: {
                                    idx: ${this.personidx},
                                    name: "${this.name}",
                                    samAccountName: "${this.samaccountname}",
                                    extension: ${this.extension},
                                    telephoneNumber: "${this.telephone}",
                                    maxUC: "${this.maxuc}",
                                    tMobile: "${this.tmobile}",
                                    }) {
                                        id,
                                        name,
                                        samAccountName,
                                    }
                                }
                            `,
                        }, {
                            headers: {
                            'x-api-key': this.GRAPHQL_API_KEY,
                            Authorization: 'Bearer ' + this.GRAPHQL_BEARER_TOKEN,
                            }
                        }
                        );
                        const result = queryResult.data.data;
                        this.names = result;
                    };
                    saveNewUserPhone();
                    this.clearFields();
                    this.$toast.open(customMessage.successMsg('User created successfully'), {});
                }
            });
            this.eventBus.on("clear-delete-user", ({typeAD, typeProfile, typeContact, typeMisc}) => {
                this.typeAD = typeAD;
                this.typeProfile = typeProfile;
                this.typeContact = typeContact;
                this.typeMisc = typeMisc;
                this.typeNewUser = "read";
            });
        },
        methods: {
            editAD() {
                this.typeAD = "edit";
            },
            saveAD() {
                if (this.name.trim() === '') {
                   this.$toast.open(customMessage.warningMsg('The name field cannot be empty'), {});
                } else {
                    const savePersonAD = async () => {
                        const queryResult = await axios.post (
                        GRAPHQL_API_URI, {
                            query: `
                                mutation {
                                    updatePerson(input: {
                                    name: "${this.name}",
                                    samAccountName: "${this.samaccountname}",
                                    mail: "${this.email}",
                                    title: "${this.title}",
                                    }) {
                                        id,
                                        name,
                                        samAccountName,
                                    }
                                }
                            `,
                        }, {
                            headers: {
                            'x-api-key': this.GRAPHQL_API_KEY,
                            Authorization: 'Bearer ' + this.GRAPHQL_BEARER_TOKEN,
                            }
                        }
                        );
                        const result = queryResult.data.data;
                        this.names = result.saveAD;
                    };
                    savePersonAD();
                    this.typeAD = "read"
                }
            },
            exitAD() {
                this.typeAD = "read";
            },
            editProfile() {
                this.typeProfile = "edit";
            },
            saveProfile() {
                    const savePersonProfile = async () => {
                    const queryResult = await axios.post (
                    GRAPHQL_API_URI, {
                        query: `
                            mutation {
                                updatePerson(input: {
                                name: "${this.name}",
                                manager: "${this.manager}",
                                department: "${this.department}",
                                office: "${this.office}",
                                company: "${this.company}",
                                }) {
                                    id,
                                    name,
                                    samAccountName,
                                }
                            }
                        `,
                    }, {
                        headers: {
                        'x-api-key': this.GRAPHQL_API_KEY,
                        Authorization: 'Bearer ' + this.GRAPHQL_BEARER_TOKEN,
                        }
                    }
                    );
                    const result = queryResult.data.data;
                    this.names = result.saveProfile;
                };
                savePersonProfile();
                this.typeProfile = "read"
            },
            exitProfile() {
                this.typeProfile = "read";
            },
            editContact() {
                this.typeContact = "edit"
            },
            saveContact() {
                if (isNaN(this.extension) || this.extension.toString().trim() === '') {
                   this.$toast.open(customMessage.warningMsg('The extension field has to be a number'), {});
                }else if (this.extension.length > 1 && this.extension.toString().charAt(0) === '0') {
                   this.$toast.open(customMessage.warningMsg('Enter 0 for no extension'), {});   
                } else {
                        const savePersonContact = async () => {
                        const queryResult = await axios.post (
                        GRAPHQL_API_URI, {
                            query: `
                                mutation {
                                    updatePhone(input: {
                                    name: "${this.name}",
                                    extension: ${this.extension},
                                    telephoneNumber: "${this.telephone}",
                                    maxUC: "${this.maxuc}",
                                    tMobile: "${this.tmobile}",
                                    }) {
                                        id,
                                        name,
                                        samAccountName,
                                        tMobile,
                                        extension
                                    }
                                }
                            `,
                        }, {
                            headers: {
                            'x-api-key': this.GRAPHQL_API_KEY,
                            Authorization: 'Bearer ' + this.GRAPHQL_BEARER_TOKEN,
                            }
                        }
                        );
                        const result = queryResult.data.data;
                        this.names = result.saveContact;
                    };
                    savePersonContact();
                    this.typeContact = "read"
                }
            },
            exitContact() {
                this.typeContact = "read";
            },
            editMisc() {
                this.typeMisc = "edit";
            },
            saveMisc() {
                    if (this.homedirectory.includes("\\")){
                        this.homedirectory = this.homedirectory.replace(/\\/g,'\\\\');
                    }
                    const savePersonMisc = async () => {
                    const queryResult = await axios.post (
                    GRAPHQL_API_URI, {
                        query: `
                            mutation {
                                updatePerson(input: {
                                name: "${this.name}",
                                employeeNumber: "${this.employeenumber}",
                                homeDirectory: "${this.homedirectory}",
                                assetsID: "${this.assetsid}",
                                asmEmail: "${this.asmemail}",
                                }) {
                                    id,
                                    name,
                                    samAccountName,
                                }
                            }
                        `,
                    }, {
                        headers: {
                        'x-api-key': this.GRAPHQL_API_KEY,
                        Authorization: 'Bearer ' + this.GRAPHQL_BEARER_TOKEN,
                        }
                    }
                    );
                    const result = queryResult.data.data;
                    this.names = result.saveMisc;
                };
                savePersonMisc();
                this.typeMisc = "read"
            },
            exitMisc() {
                this.typeMisc = "read";
            },
            clearFields() {
                this.typeAD = "";
                this.typeProfile = "";
                this.typeContact = "";
                this.typeMisc = "";
                this.typeNewUser = "read";
                this.eventBus.emit("reset-newuser-button", {
                        newuser: "new",
                });
            },
            /* validateExtension(value) {
                if (isNaN(value)) {
                    this.$toast.open(customMessage.warningMsg('The extension field has to be a number'), {});
                }
            }, */
        },
        watch: {
            /* extension(value) {
                this.validateExtension(value);
            } */
        },
        mounted() {
            if (localStorage.apikey) {
                this.GRAPHQL_API_KEY = localStorage.apikey;
            }
            if (localStorage.apisecret) {
                this.GRAPHQL_BEARER_TOKEN = localStorage.apisecret;
            }
        }
    }
</script>
/* this.$toast.open({
    message: 'User created successfully',
    type: 'success',
    position: "top",
    duration: 5000,
    queue: true
}); */

/* module.exports = {
    successMsg: {
        //message: 'User created successfully',
        type: 'success',
        position: "top-right",
        duration: 5000,
        queue: true
    }
}; */

/* const successMsg = (message) => {
    return {
        message: message,
        type: 'success',
        position: "top-right",
        duration: 5000,
        queue: true
    };
};
module.exports = successMsg;
 */

module.exports.successMsg = (message) => {
    return {
        message: message,
        type: 'success',
        position: "top",
        duration: 3000,
        queue: false
    };
};

module.exports.errorMsg = (message) => {
    return {
        message: message,
        type: 'error',
        position: "top",
        duration: 3000,
        queue: false
    };
};

module.exports.warningMsg = (message) => {
    return {
        message: message,
        type: 'warning',
        position: "top",
        duration: 3000,
        queue: false
    };
};

module.exports.infoMsg = (message) => {
    return {
        message: message,
        type: 'info',
        position: "top",
        duration: 3000,
        queue: false
    };
};


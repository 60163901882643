<template>
    <div class="modal-backdrop">
    <div class="modal">
      <header class="modal-header">
        <slot name="header">
          API Settings
        </slot>
        <button
          type="button"
          class="btn-close"
          @click="closeAPIModal"
        >
          x
        </button>
      </header>

      <section class="modal-body">
        <slot name="body">
          <div>
            <label class="modal-text">API Key:</label>
            <input class="modal-input" type="text" v-model="apikey">
          </div>
          <br>
          <div>
            <label class="modal-text">API Sec:</label>
            <input class="modal-input" type="text" v-model="apisecret">
          </div>
        </slot>
       </section>
       <button
          type="button"
          class="btn-blue"
          @click="saveAPIModal"
        >
          Save
        </button>
    </div>
  </div>
</template>

<style>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    background: #242635;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    font-family: 'Poppins';
  }

  .modal-input {
    border: 0;
    border-radius: 5px;
    padding: 0.5rem;
    outline: none;
    color: #cccccc;
    background-color: #3a3d55;
  }
    .modal-input:focus {
        outline: none !important;
    }
  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #cccccc;
    color: #82A8F2;
    justify-content: space-between;
  }

  .modal-footer {
    position: relative;
    padding: 20px 10px;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
    
  }

  .modal-text {
    padding: 5px;
    color: #cccccc;
    margin-right: 10px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #82A8F2;
    background: transparent;
  }

  .btn-blue {
    background: #82A8F2;
    border-radius: 5px;
    border: 0;
    color: #ffffff;
    padding: 0.5rem;
    cursor: pointer;
    margin: 25px;
  }
</style>

<script>
  export default {
    name: 'APIModal',
    data() {
        return {
            apikey: '',
            apisecret: '',
        };
    },
    mounted() {
        if (localStorage.apikey) {
            this.apikey = localStorage.apikey;
        }
        if (localStorage.apisecret) {
            this.apisecret = localStorage.apisecret;
        }
    },
    watch: {
        /* apikey(value) {
            localStorage.apikey = value;
        },
        apisecret(value) {
            localStorage.apisecret = value;
        }, */
    },
    methods: {
      saveAPIModal() {
        localStorage.apikey = this.apikey;
        localStorage.apisecret = this.apisecret;
        this.$emit('close');
      },
      closeAPIModal() {
        this.$emit('close');
      },
    },
  };
</script>

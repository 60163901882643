<template>
    <div class="modal-backdrop">
    <div class="modal">
      <header class="modal-header">
        <div class="modal-header-text">
          Delete user?
        </div>
        <button
          type="button"
          class="btn-close"
          @click="closeDeleteModal"
        >
          x
        </button>
      </header>

      <section class="modal-body">
        <slot name="body">
          <div><label class="modal-text">{{ delusername }}</label></div>
          <div><label class="modal-msg">will be deleted from the database?</label></div>
        </slot>
       </section>

     <!--  <footer class="modal-footer">
        <slot name="footer">
          <label class="modal-text">will be deleted from the database.</label>
        </slot>
      </footer> -->
       <button
          type="button"
          class="btn-blue"
          @click="deleteConfirmed"
        >
          Yes
        </button>
    </div>
  </div>
</template>

<style>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    background: #242635;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    font-family: 'Poppins';
    width: 22rem;
  }

  .modal-input {
    border: 0;
    border-radius: 5px;
    padding: 0.5rem;
    outline: none;
    color: #cccccc;
    background-color: #3a3d55;
  }
    .modal-input:focus {
        outline: none !important;
    }
  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #cccccc;
    color: #82A8F2;
    justify-content: space-between;
  }

  .modal-header-text {
    color: red;
    font-weight: bold;
  }

  .modal-footer {
    position: relative;
    padding: 20px 10px;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
    text-align: center;
  }

  .modal-text {
    padding: 5px;
    color: #ffffff;
    text-align: center;
    font-weight: bold;
  }

  .modal-msg {
    padding: 5px;
    color: #ffffff;
    text-align: center;
    font-weight: bold;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #82A8F2;
    background: transparent;
  }

  .btn-blue {
    background: #82A8F2;
    border-radius: 5px;
    border: 0;
    color: #ffffff;
    padding: 0.5rem;
    cursor: pointer;
    margin: 25px;
  }
</style>

<script>
  import axios from 'axios';
  const GRAPHQL_API_URI = "https://nexidom.com:4001/graphql";

  export default {
    name: 'APIModal',
    data() {
        return {
            GRAPHQL_API_KEY: '',
            GRAPHQL_BEARER_TOKEN: '',
            delusername: '',
            deluserid: '',
            delcontactid: '',
        };
    },
    mounted() {
        if (localStorage.apikey) {
            this.GRAPHQL_API_KEY = localStorage.apikey;
        }
        if (localStorage.apisecret) {
            this.GRAPHQL_BEARER_TOKEN = localStorage.apisecret;
        }
    },
    methods: {
      deleteConfirmed() {
        const deletePerson = async () => {
                const queryResult = await axios.post (
                  GRAPHQL_API_URI, {
                    query: `
                        mutation {
                            delPerson(id:"${this.deluserid}") {
                                contacts{
                                    id
                                }
                            }
                        }
                    `,
                  }, {
                    headers: {
                      'x-api-key': this.GRAPHQL_API_KEY,
                      Authorization: 'Bearer ' + this.GRAPHQL_BEARER_TOKEN,
                    }
                  }
                );
                const result = queryResult.data.data;
                this.names = result.delPerson;
        };
        deletePerson();
        const deleteContactID = async () => {
                const queryResult = await axios.post (
                  GRAPHQL_API_URI, {
                    query: `
                        mutation {
                            delContactID(id:"${this.delcontactid}") {
                                name
                                idx
                            }
                        }
                    `,
                  }, {
                    headers: {
                      'x-api-key': this.GRAPHQL_API_KEY,
                      Authorization: 'Bearer ' + this.GRAPHQL_BEARER_TOKEN,
                    }
                  }
                );
                const result = queryResult.data.data;
                this.names = result.delContactID;
        };
        deleteContactID();
        this.eventBus.emit("clear-delete-user", {
            typeAD: "",
            typeProfile: "",
            typeContact: "",
            typeMisc: "",
        });
        this.$emit('close');
      },
      closeDeleteModal() {
        this.$emit('close');
      },
    },
    created() {
      this.eventBus.on("confirm-del-user", ({delThisUser, delThisUserID, delThisContactID}) => {
          this.delusername = delThisUser;
          this.deluserid = delThisUserID;
          this.delcontactid = delThisContactID;
      });
    }
  };
</script>
